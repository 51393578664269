import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { Heading2Mixin } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export const CREATE_HOMEGROUP_LOCATION_FORM_ID =
  "create-homegroup-location-form"

export function HomeGroupLocationNameStep({
  homeGroupName,
  setHomeGroupName,
  isError,
  onSubmit,
}: {
  homeGroupName: string
  setHomeGroupName: (name: string) => void
  isError: boolean
  onSubmit?: (options: { name: string }) => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <FormBox
      id={CREATE_HOMEGROUP_LOCATION_FORM_ID}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit?.({ name: homeGroupName })
      }}
    >
      <Title>{t(langKeys.organizations_home_groups_create_title)}</Title>

      <MTextField
        label={t(langKeys.name)}
        value={homeGroupName}
        onChange={(value) => setHomeGroupName(value)}
        required
      />

      {isError && (
        <AlertBox type="error">
          {t(langKeys.failed_something_went_wrong)}
        </AlertBox>
      )}
    </FormBox>
  )
}

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const Title = styled.div`
  ${Heading2Mixin};
`

const AlertBox = styled(MBanner)`
  margin-top: ${spacing.M};
`
