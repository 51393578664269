import { useState } from "react"

import {
  CREATE_HOMEGROUP_LOCATION_FORM_ID,
  HomeGroupLocationNameStep,
} from "src/components/HomeGroups/HomeGroupsWizard/HomeGroupLocationNameStep"
import { usePostHomeGroup } from "src/data/homeGroups/queries/homeGroupQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"

export interface IHomeOption {
  id: string
  name: string
}

export function HomeGroupsLocationWizard() {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()
  const [currentStep, setCurrentStep] = useState(0)
  const [homeGroupName, setHomeGroupName] = useState("")
  const { org } = useOrganization()
  const postHomeGroup = usePostHomeGroup()

  function createHomeGroup({ name }: { name: string }) {
    postHomeGroup.mutate(
      { orgId: org.id, body: { name, type: "area" } },
      { onSuccess: (hg) => navigate(Routes.HomeGroupDetails.path(hg.id)) }
    )
  }

  const stepper: IWizardStep[] = [
    {
      id: CREATE_HOMEGROUP_LOCATION_FORM_ID,
      component: (
        <HomeGroupLocationNameStep
          homeGroupName={homeGroupName}
          setHomeGroupName={setHomeGroupName}
          isError={postHomeGroup.isError}
          onSubmit={({ name }) => createHomeGroup({ name })}
        />
      ),
      onNext: () => createHomeGroup({ name: homeGroupName }),
      nextButtonProps: { form: CREATE_HOMEGROUP_LOCATION_FORM_ID },
      nextButtonLabel: t(
        langKeys.organizations_home_group_create_stepper_button_label
      ),
      hideProgress: true,
      hideBackButton: true,
    },
  ]

  const wizardTitle = `${t(langKeys.organizations_home_group_add_stepper_title)}${homeGroupName ? ": " : ""}${homeGroupName}`
  return (
    <FullscreenWizard
      title={wizardTitle}
      steps={stepper}
      currentStep={currentStep}
      onClose={() => navigate(Routes.HomeGroupLocations.location({}))}
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}
